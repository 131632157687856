<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>베팅번호</li>
        <li>{{ item["c_idx"] }}</li>
      </ul>
      <ul>
        <li>카지노이름</li>
        <li>{{ item["c_casino"] }}</li>
      </ul>
      <ul>
        <li>테이블 이름</li>
        <li>{{ item["c_table_idx"] }}</li>
      </ul>
      <ul>
        <li>슈번호</li>
        <li>{{ item["c_shoe_idx"] }}</li>
      </ul>
      <ul>
        <li>게임번호</li>
        <li>{{ item["c_game_idx"] }}</li>
      </ul>
      <ul>
        <li>베팅타입</li>
        <li>{{ item["c_bet_type"] }}</li>
      </ul>
      <ul>
        <li>베팅결과</li>
        <li>{{ item["c_bet_result"] }}</li>
      </ul>
      <ul>
        <li>당첨예상금액</li>
        <li>{{ item["c_result_money"] }}</li>
      </ul>
      <ul>
        <li>베팅금액</li>
        <li>{{ item["c_bet_money"] }}</li>
      </ul>
      <ul>
        <li>베팅 후 잔액</li>
        <li>{{ item["c_after_money"] }}</li>
      </ul>
      <ul>
        <li>{{ $t('table.head.sportj') }}</li>
        <li>{{ item["c_reg_date"] }}</li>
      </ul>
      <ul>
        <li>게임결과(플레이어)</li>
        <li class="card">
           <em :class="item['pc1']">[{{ item["pc1"] }}]</em>
           <em :class="item['pc2']">[{{ item["pc2"] }}]</em>
           <em :class="item['pc3']">[{{ item["pc3"] }}]</em>
        </li>
      </ul>
      <ul>
        <li>게임결과(뱅커)</li>
        <li class="card">
           <em :class="item['bc1']">[{{ item["bc1"] }}]</em>
           <em :class="item['bc2']">[{{ item["bc2"] }}]</em>
           <em :class="item['bc3']">[{{ item["bc3"] }}]</em>
        </li>
      </ul>
      <ul>
        <li>게임결과(WIN)</li>
        <li>{{ item["c_game_result"] }} ( 1:banker, 2:player, 3:tie)</li>
      </ul>
      <ul>
        <li>플레이어 페어여부</li>
        <li>{{ item["pp"] }} ( 페어라면 1 )</li>
      </ul>
      <ul>
        <li>뱅커 페어여부</li>
        <li>{{ item["bb"] }} ( 페어라면 1 )</li>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode13',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
